<template>
    <div class="lotteryHomeCard">
        <div class="left">
            <div class="next_info">
                <div class="first_row">
                    <p>{{nextperiod.period_no | formatComingLotteryType2(nextperiod.draw_time, lotteryCode, nextperiod.period_no)}}</p>
                    <!-- <img class="live" src="@/img/player.png"> -->
                </div>
                <div class="third_row" v-if="lotteryDrawTime[lotteryCode] > 0">
                    <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                        {{lotteryDrawTime[lotteryCode] | reciprocalDay}}
                        <p>天</p>
                    </div>
                    <div class="time_block" v-if="lotteryConfig[lotteryCode].lotteryType==1">
                        {{lotteryDrawTime[lotteryCode] | reciprocalHour}}
                        <p>时</p>
                    </div>
                    <div class="time_block">
                        {{lotteryDrawTime[lotteryCode] | reciprocalMinute}}
                        <p>分</p>
                    </div>
                    <div class="time_block">
                        {{lotteryDrawTime[lotteryCode] | reciprocalSecond}}
                        <p>秒</p>
                    </div>
                </div>
                <div  class="third_row wait"  v-else>
                    <div class="time_block">
                        <img class="rotating" src="@/img/return.png">
                        开奖中...
                    </div>
                </div>
            </div>
            <div class="bg">
                <img class="img" :src="require(`@/img/lottery_background/${lotteryCode}_bg.png`)">
                <img class="icon" :src="require(`@/img/ballicon/${lotteryCode}_icon.png`)">
            </div>
        </div>
        <div class="right">
            <div class="info">
                <div class="period_row">
                    <i class="icon">
                        <img class="star" src="@/img/star.png"> 
                    </i>
                 
                    <div v-if="lotteryConfig[lotteryCode].displayperiodType=='nationwide'">
                        第 {{currentperiod.period_no | checkDisplayPeriod(lotteryCode, currentperiod.period_no)}} 期

                        <div class="item-detail" v-if='lotteryConfig[lotteryCode].lotteryType==1'>
                                <span v-if="lotteryCode==='xglhc'">每周二、四、六  21:30:00开奖 </span>
                                <span v-if="lotteryCode==='qxc'">每周二、五、日  21:30:00开奖 </span>
                                <span v-if="lotteryCode==='lucky7daily'">每日22:00:00开奖 </span>
                                <span v-if="lotteryCode==='pl3'">每日21:30:00开奖 </span>
                                <span v-if="lotteryCode==='pl5'">每日21:30:00开奖 </span>
                                <span v-if="lotteryCode==='pl5daily'">每日21:00:00开奖 </span>
                                <span v-if="lotteryCode==='kl8lhc'">每日21:30:00开奖 </span>
                        </div>
                        <div v-else class="item-detail">当前<span> {{drawCount}} </span>期,剩<span> {{totalCount-drawCount}} </span>期</div>
                    </div>
                   
                </div>
                <div class="draw_number_row" :class="`${lotteryCode!='xglhc' && lotteryCode!='kl8lhc' && lotteryCode !='qxc' && lotteryCode !='lucky7daily' && lotteryCode !='pl3' && lotteryCode !='pl5'  && lotteryCode !='pl5daily' ? 'h200': ''}`">
                    <div class="drawballs" v-show="lotteryCode !='xglhc' && lotteryCode !='sgplhc' && lotteryCode !='kl8lhc'">
                        <template v-if="lotteryCode !='lucky7daily'"><!--英國天天除外-->
                            <div :class="`${lotteryCode} ball ball-${item} ${LotteryTwenty.includes(lotteryCode) ? 'twentyBall' : ''} `" v-for="(item,index) in lastDraw" :key="index">   
                                <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                                <span v-else-if="padLeftLottery2.includes(lotteryCode)">{{parseInt(item)}}</span>
                                <span v-else>
                                    {{item}}
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="lastDraw[0] == ''">
                                <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 6" :key="index+'_3'">
                                    <span> ? </span>
                                </div>
                                <div class="add" v-if="padLeftLottery.includes(lotteryCode)">+</div>
                                <div :class="`${lotteryCode} ball ball-99`">
                                    <span> ? </span>
                                </div>
                            </template>
                            <template v-else>
                                    <div v-for="(item,index) in lastDraw.slice(0, 6)"
                                    :key="index"
                                    :class="`${lotteryCode} ball ball-${parseInt(item).toString().substring(-1)}`">
                                      <span v-if="padLeftLottery.includes(lotteryCode)">{{item | padLeft(2)}}</span>
                                    </div>
                                    <div class="add" v-if="padLeftLottery.includes(lotteryCode)">+</div>
                                    <div :class="`${lotteryCode} ball ball-${lastDraw[lastDraw.length-1]}`">
                                    <span v-if="padLeftLottery.includes(lotteryCode)">{{lastDraw[lastDraw.length-1]  | padLeft(2)}}</span>
                                    </div>

                                    <div :class="`${lotteryCode} ball ball-99`" v-for="(item,index) in 7- lastDraw.length" :key="index+'_2'">
                                    <span> ? </span>
                                    </div>
                            </template>
                        </template>
                    </div> 
                    <!--- 六合系列 -->
                    <div v-if="lastDraw.length > 0" class="drawballs lh" v-show="lotteryCode==='xglhc' ||lotteryCode==='kl8lhc' || lotteryCode==='sgplhc'">
                        <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[index])}`" v-for="(item,index) in 6" :key="index">{{lastDraw[index] | padLeft(2)}}</div>
                        <div class="add"> + </div>
                        <div :class="`${lotteryCode} ball ball-${parseInt(lastDraw[6])}`">{{lastDraw[6] | padLeft(2)}}</div>  
                    </div>
                    <!-- 5分彩 -->
                </div>
                <LhcTable v-if="lotteryCode=='xglhc' || lotteryCode=='kl8lhc' || lotteryCode=='sgplhc'" :display="tableData" :lotteryCode="lotteryCode" />
                <DefaultTable v-else :display="tableData" :lotteryCode="lotteryCode" />
                <div class="option">
                    <a class="itme active" :href="`/#/lottery/${lotteryCode}`">
                        <div class="icon">
                            <icon name="history" ></icon>
                        </div>
                        <div class="text">
                            开奖历史
                        </div>
                    </a>
                    <a v-if="lotteryConfig[lotteryCode].liveStream && lotteryCode !='kl8lhc'"  class="itme active" style="cursor: pointer; margin-left: 10px;" @click="openLiveStream">
                        <div class="icon">
                            <icon name="liveStreamPlay_blue" ></icon>
                        </div>
                        <div class="text">
                            开奖直播
                        </div>
                    </a>
                   <a v-if="lotteryCode =='kl8lhc'" class='launch-kl8-website' href='https://www.cwl.gov.cn/ygkj/kjzb/' target='_blank' rel='noopener noreferrer'>
                     <img src='@/img/icon_lottery.png' width='18' /> 开奖验证
                   </a>
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {lotteryConfig} from "@/assets/lotteryConfig.js";
import DefaultTable from '@/components/lottery_home_card/default_home_table.vue'
import LhcTable from '@/components/lottery_home_card/lhc_home_table.vue'

export default {
    name: 'lotteryHomeCard',
    props: {
        lotteryCode: String,
    },
    components: {
        DefaultTable,
        LhcTable,
    },
    data() {
        return {
            nextperiod:{},
            currentperiod: {},
            lastDraw: [],
            lotteryConfig: lotteryConfig,
            tableData: [],
            padLeftLottery: ['azxy8','jisukuaile','sgplhc','lucky7daily', 'infinity8', 'infinity8ex', 'cannon20', 'cannon20ex', 'lucky5', 'lucky5ex', 'xglhc'],
            LotteryTwenty:['cannon20','azxy20','cannon20ex'],
            padLeftLottery2: ['azxy10','azxy20','jisusaiche'],
            drawCount:0,
            totalCount:0,
        }
    },
    computed: {
        ...mapState([
            "lotteryInfo",
            "lotteryDrawTime"
        ]),
    },
    watch: {
        lotteryInfo: {
            handler: function(value){
                
                this.setLotteryInfo(this.lotteryCode);
            },
            immediate: true,
            deep: true,
        }
    },
    methods: {
        setLotteryInfo: function(lotteryCode) {
           
            if(!this.lotteryInfo[lotteryCode]) {
                return;
            }
            
            let lotteryInfo = this.lotteryInfo[lotteryCode];
            this.lotteryName = lotteryInfo.name; // 樂透名
            
            if(lotteryInfo) {
                // 當前資訊
                //debugger
                this.nextperiod = lotteryInfo.next_data; 
                this.drawCount= lotteryInfo.current_data.draw_count;
                this.totalCount=lotteryInfo.current_data.total_count;
                // 上期資訊
                this.currentperiod = lotteryInfo.current_data; 
                this.lastDraw = this.currentperiod.draw.length>0 ?  this.$options.filters.splitDrawNumber(this.currentperiod.draw.split(","),lotteryCode) : [];
                this.tableData = this.currentperiod.analysis;
            }                
        },
        openLiveStream: function(){
            this.$emit('openVedio', this.lotteryCode, this.lotteryInfo[this.lotteryCode]);
        }
    },
    mounted() {
    }
}
</script>


<style lang="scss" scoped>
img{
    width: 100%;
}
.lotteryHomeCard{
    width: 9.91rem;
    height: 400px;
    display: flex;
    margin-bottom: 40px;
    .left{
        z-index: 1;
        position: relative;
        padding: 0.3rem;
        box-sizing: border-box;
        width: 3.52rem;
        height: 385px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .bg{
            position: absolute;
            top: 0px;
            left: 0px;
            .img{
                width: 3.52rem;
                height: 4.1rem;
            }
            .icon{
                position: absolute;
                top: 30px;
                left: 85px;
                width: 1.7rem;
                height: 1.7rem;
            }
        }        
        .next_info{
            z-index: 2;
            text-align: center;
            font-weight: bold;
            color: #0f2957;
            position: relative;
            .first_row{
                justify-content: center;
                font-size: 26px;
                display: flex;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                font-family: 'Microsoft Yahei';
                .live{
                    width: 0.63rem;
                    height: 0.24rem;
                }
            }

            .third_row{
                margin-top: 35px;
                display: flex;
                width: auto;
                height: 75px;
                min-width: 292px;
                justify-content: center;
                .time_block{
                    padding-top: 0.15rem;
                    box-sizing: border-box;
                    width: 0.67rem;
                    height: 0.74rem;
                    background-color: #0f2957;
                    border-radius: 5px;
                    color: #ffffff;
                    font-family: 'Open Sans';
                    margin-right: .08rem;
                    text-align: center;
                    font-size: .25rem;
                    p {
                        margin-top: 0.06rem;
                        font-family: SFPro;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                        color: #adadad;
                    }
                  
                }
                &.wait{
                     .time_block{
                          width: 142px;
                          height: 40px;
                          margin-right: 0;
                          padding-top:0;
                          font-size: 20px;
                          color: #fff;
                          display: flex;
                          align-items: center;
                          justify-content: space-evenly;
                          img{
                              width: 20px;
                          }
                     }
                }
            }
        }
    }  
    .right{
        margin-left: -0.45rem;
        display: flex;
        align-items: center;
        .option{
            height: 0.5rem;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            .itme{
                color: #c4c4c4;
                display: flex;
                align-items: center;
                background-color: #f0ebd0;
                padding: 2px 5px;
                border-radius: 5px;
                border: 1px solid #e0d6a7;
                .icon{
                    width: 0.22rem;
                    height: 0.22rem;
                    margin-right: 0.02rem;
                }
                .text{
                    font-weight: 600;
                    font-size: 15px;
                    text-align: left;
                    line-height: 0.26rem;
                }
                .text.disable{
                    color: #c4c4c4;
                }
                &.active{
                    color: #0f2957;
                }
            }
        }
        .info{
            border-radius: 20px;
            box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
            border: solid 3px #e0d6a7; 
            background: #fff;
            width: 8.75rem;
            height: 3.32rem;
            padding: 0.27rem 0.25rem 0 0.74rem;
            box-sizing: border-box;
            display: grid;
            grid-template-rows: 10% auto auto;
            .period_row{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Open Sans';
                font-size: 17px;
                font-weight: bold;
                text-align: left;
                color: #0f2957;
                div{
                    margin-left: 5px;
                    font-size: 20px;
                    display: flex;
                    align-items: flex-end;
                }
                .item-detail{
                    margin-left: 24px;
                    color: #999;
                    font-size: 14px;
                }
               
            }
            .draw_number_row{
                    display: flex;
                    align-items: flex-start;
                    margin-left: 10px;
                    margin-top: 26px;
                     &.h200{
                        height: 170px;
                    }
               
                .drawballs{
                    display: flex;
                    flex-flow: wrap;
                    
                    .ball{
                        margin-left: .16rem;
                        color: #fff;
                        font-size: .34rem;
                        @include ballSize(.53rem);
                        margin-bottom: .12rem;
                    } 
                     .twentyBall:nth-child(10){
                        margin-right: 70px;
                    }       
                }
                .drawballs.lh{
                    .ball{   
                        &:nth-child(6){
                            margin-right: 0;
                        }
                    }     
                }
            }
            .info_table{
               
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #7e7e7e;
                padding: unset;
                th{
                    border: 1px solid #e0d6a7;
                    height: 0.5rem;
                    background-color: #f0ebd0;                    
                }
                tr:nth-child(odd) td{
                    background-color: #f0ebd0;
                }
                tr:nth-child(even) td{
                    background-color: #fff;
                }
                
                td{
                    border: 1px solid #e0d6a7;
                    height: 50px;
                }
            }
        }        
    }
}

.line{
    width: 1px;
    height: .14rem;
    background: #cccc;
    line-height: 40px;
    margin: 0 .16rem;
}
.add{
    font-size: 50px;
    width: 50px;
    height: 50px;
    line-height: 1;
    margin: 0 0 0 0.1rem;
    color: #646363;
}

.ball-99{
    background: #505050
}
.launch-kl8-website{
  display:flex;
  background:#ec6931;border-radius:5px;
  padding:5px 10px;
  margin-left:10px;
  flex-wrap:nowrap;
  color:#fff;
  font-weight:bold;
  align-items:center;
}
.launch-kl8-website img{width:auto;margin-right:8px;}
</style>